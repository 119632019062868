import React, { useEffect, useRef } from 'react'
import './Header.css'
import BannerImg from '../../Assets/Banner.jpg'

const HeaderSec = () => {




  return (
    <div className='Banner-Wrapper'>
       <div className='Header-Container'>
      <div className='Banner-Img-Container'>
         {/* <img className='Banner-Img' src={BannerImg} alt="Banner-Img" /> */}
         <video className='Banner-Img' src="https://video.wixstatic.com/video/11062b_81ace9ac039247ed98d043150330cada/1080p/mp4/file.mp4" autoPlay muted loop></video>
      </div>
       <div className='Header-Container-Inside'>
          <div className='BannerContent'>
              <h3>PROGG: Innovative Software Solutions & Website Development</h3>
              <p>Feel free to tweak this to better fit your brand's voice and specific offerings!</p>
              <button className='css-button-rounded--black'>
                 <a href='#StrategicBM'>Know More</a>
              </button>
          </div>
       </div>
    </div>
    </div>
  )
}

export default HeaderSec