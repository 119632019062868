import React, { useState } from 'react'
import './CoreOffering.css';
import CoreOffCard from '../../Cards/CoreOffCard'
import { OurCoreOffer } from '../../Data';

const CoreOffering = () => {

    let [State , SetState] = useState(OurCoreOffer)

    return (
        <div className='CoreOffering'>
          <h1 className='CoreOffering-Head'>Solution Spotlight</h1>
            <div className='Core-off-Card-Container'>
                 {State.map((val)=> <CoreOffCard {...val} key={val.id} />)}
            </div>
        </div>
  )
}

export default CoreOffering