import React from 'react'
import './CoreOff.css'

const index = ({id , Title , Desc , Icon}) => {
  return (
    <div className='Off-Card-Container'>
       <div className='IconContainer'>        
        <div className='webIcon'>{Icon}</div>
       </div>
       <h1>{Title}</h1>
       <p className='Card-Description'>{Desc}</p>
    </div>
  )
}

export default index