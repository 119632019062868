import React, { useEffect, useRef, useState } from 'react'
import './Nav.css'
import { useContextApi } from '../Context/contextAPI'
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll';


const Index = () => {

     let [width , SetWidth] = useState(window.innerWidth)
     let { Toggle , SetToggle } = useContextApi()

  
   useEffect(()=>{
     window.addEventListener('resize' , ()=>{
           SetWidth(window.innerWidth)
     })
   })

    let Check = width < 700

  return (
    <div className='Navbar-Container'>
      <div className='Navbar'>
        <div>
             <a className='logo' href='#'>Progg</a>
        </div>
        <ul className= {!Toggle && Check ? "NavItemHeight" :''}>
            <li onClick={()=>SetToggle(!Toggle)}>
                 <a href='#ServicesWrapper'>Services</a>
            </li>
            <li onClick={()=>SetToggle(!Toggle)}>
                 <a href='#ProductContainer'>Product</a>
            </li>
            <li onClick={()=>SetToggle(!Toggle)} >
                 <a href='#About-us'> About us</a>
            </li>
            <li onClick={()=>SetToggle(!Toggle)}>
                 <a href='#Contact-Container'> Contact us</a>
            </li >
        </ul>
            {
            !Toggle ?
             <HiOutlineMenuAlt3 className='MenuIcon' onClick={()=>SetToggle(!Toggle)}/> 
              :
              <IoCloseOutline className='MenuIcon' onClick={()=>SetToggle(!Toggle)}/>
            } 
    </div>
    </div>
  )
}

export default Index