import React from 'react'
import './Footer.css'

const Footer = () => {

  const currentYear = new Date().getFullYear();
  return (
    <div className='Footer-Container'>
      <h1>THE PROGG</h1>
      <p>Redefine Your Business</p>
      <a href="mailto:info@progg.in">info@progg.in</a>
      <p>© {currentYear}, Progg. All Rights Reserved.</p>
    </div>
  )
}

export default Footer