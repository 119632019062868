import { SiWebtrees } from "react-icons/si";
import { SiWebpack } from "react-icons/si";
import { GrServices } from "react-icons/gr";
import { GrTechnology } from "react-icons/gr";
import img1 from '../Assets/img1.jpg'
import img2 from '../Assets/bsimg2.jpg'
import img3 from '../Assets/scal.jpg'
import img4 from '../Assets/insight.jpg'
import img5 from '../Assets/cming.jpg'

  export let OurCoreOffer = [
    {
      id:1,
      Icon:<SiWebtrees/>,
      Title:'Web Development',
      Desc:`From dynamic websites to complex web applications, Progg’s expert team builds robust and scalable web solutions that captivate 
            users and perform seamlessly. Whether you're looking for a modern e-commerce site, a responsive corporate website, or a custom web
            application, we’ve got you covered`
    },
    {
      id:2,
      Icon:<SiWebpack/>,
      Title:'Customized Software Solutions',
      Desc:`We understand that one size doesn’t fit all. Progg develops tailored software solutions that address your specific business challenges
            and goals. Our customized approach ensures that our software perfectly aligns with your processes, providing a strategic advantage.`
    },
    {
      id:3,
      Icon:<GrServices/>,
      Title:'End-to-End Services',
      Desc:`We handle every aspect of the development lifecycle—from initial consultation and design to development, deployment, and ongoing 
            support. Our comprehensive services ensure that your project is completed on time and to your satisfaction.`
    },
    {
      id:4,
      Icon:<GrTechnology/>,
      Title:'Innovative Technology',
      Desc:`Utilizing the latest technologies and development practices, Progg delivers solutions that are not only functional but also
            future-proof. Our focus on innovation helps your business stay ahead in a competitive landscape.`
    },
    
]


export let BMStrategic = [
  {
    id:1,
    img:img1,
    Title:'Strategic Planning and Execution',
    Desc:`We assist in crafting and implementing effective business strategies tailored to your goals. Our strategic management solutions help you navigate challenges, capitalize on opportunities, and position your business for long-term success.`
  },
  {
    id:2,
    img:img2,
    Title:'Business Process Optimization',
    Desc:`Progg identifies and optimizes key business processes to ensure they align with your strategic objectives. Our solutions enhance workflow efficiency, improve productivity, and ensure that every aspect of your operation supports your overarching goals.`
  },
  {
    id:3,
    img:img3,
    Title:'Scalability',
    Desc:`As your business grows, Progg’s scalable solutions adapt to your expanding needs. Whether it’s increasing system capacity or adding new features, we ensure that your software infrastructure can support your growth trajectory.
`
  },
  {
    id:4,
    img:img4,
    Title:'Data-Driven Insights',
    Desc:`We leverage data analytics and business intelligence tools to provide actionable insights. Our solutions enable you to make informed decisions, track performance metrics, and refine strategies based on real-time data.`
  },
  {
    id:5,
    img:img5,
    Title:'Change Management',
    Desc:`Successful upscaling often requires navigating change. Progg supports you through the process by providing guidance on managing transitions, training staff, and integrating new systems seamlessly.`
  },
]