import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './BMStatCard.css'

const BMStatCard = ({ data }) => {
  return (
    <Carousel>
      {data.map(({ id, img, Title, Desc }) => (
        <Carousel.Item key={id} >
          <img className='Carousel-img' src={img} alt={Title} />
            <h3 className='Carousel-Title'>{Title}</h3>
            <p className='Carousel-Desc'>{Desc}</p>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default BMStatCard