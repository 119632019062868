import React from 'react'
import Navbar from '../Components/Navbar'
import Header from '../Components/Header'
import Services from '../Components/Services'
import Product from '../Components/Product'
import AboutUs from '../Components/AboutUs'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'

const Landing = () => {
  return (
    <div>
      <Navbar/>
      <Header/>
      
         <Services/>
      
      <Product/> 
      <AboutUs/>
      <ContactUs/>
      <Footer/>
    </div>
  )
}

export default Landing