import './App.css';
import Landing from './Pages/Landing';
import ContextAPI from './Components/Context/contextAPI';

function App() {
  return (
    <div className="App">
      <ContextAPI>
        <Landing/>
      </ContextAPI>
    </div>
  );
}

export default App;
