import React, { useEffect } from 'react'
import { BMStrategic } from '../../Data'
import './StrategicBM.css'
import BMCard from '../../Cards/BMStatCard'
import square from '../../../Assets/sqaure.png'

const StrategicBM = () => {
 

  
  return (
    <div id='StrategicBM' className='StrategicBM'>
        <h1>Strategic Business Management</h1>
        <h3>We care for your Business upscaling</h3>
        <p> <span> <img className='Square-Img' src={square} alt="img"/> </span>
          At Progg, we’re dedicated to empowering businesses with cutting-edge solutions that drive strategic growth and operational excellence. 
          Our expertise in strategic business management and upscaling ensures that your organization can achieve its full potential in an ever-evolving
           marketplace.
          </p>
       
             <div>
               <BMCard data={BMStrategic} />
             </div>
    </div>
  )
}

export default StrategicBM