import React, { useContext, useRef, useState } from 'react'
import { createContext } from 'react'

let MainContext = createContext()

export let useContextApi = ()=> useContext(MainContext)


const ContextAPI = ({children}) => {
    
     let refSection1 = useRef(null)
     let refSection2 = useRef(null)
     let refSection3 = useRef(null)
     let [Toggle , SetToggle] = useState(false)
     let refSection4 = useRef(null)

     let SectionNavigateFun = (sectionRef)=>{
      // SetToggle(!Toggle)
      console.log(sectionRef.current);
     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
     }

  return (
    <MainContext.Provider value={{refSection1 ,refSection2, refSection3 ,refSection4 , SectionNavigateFun ,Toggle , SetToggle}}>
        {children}
    </MainContext.Provider>
  )
}

export default ContextAPI