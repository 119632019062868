import React from 'react'
import img from '../../Assets/productimg.jpg'
import './Product.css'
import square from '../../Assets/sqaure.png'



const Product = () => {


  return (

    <div id='ProductContainer' className='Product-container'>
      <h1 className='Product'>Product's</h1>
      <div className='product-inside-container'>
        <div className='product-content'>
            <p> 
              <span> <img className='Square-Img' src={square} alt="img"/> </span>
              Purva Your grand Wedding and Event Planning Companion
            </p>
            <p>
              <span> <img className='Square-Img' src={square} alt="img"/> </span>
              Introducing Purva also known as Purvachitram, the premier wedding and event planning app developed by Progg. Designed to
               simplify and elevate your special occasions, Purva is your go-to solution for orchestrating flawless weddings and memorable 
               events with ease and elegance.
               </p>
            <p>
              <span> <img className='Square-Img' src={square} alt="img"/> </span>
              Goal is to provide seamless wedding experience in one search that Scalable for both B2B & DTC.
               </p>
        </div>

        <div className='product-img-container'>
           <img className='Product-img' src={img} alt="img" />
        </div>
      </div>


    </div>
  )
}

export default Product
