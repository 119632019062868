import React from 'react'
import img from '../../Assets/aboutus.jpg'
import './Aboutus.css'


const AboutUs = () => {


  return (
    <div id='About-us' className='About-us'>
      <img className='About-us-banner-img' src={img} alt="img"/>  

      <h1 className='About-Us-head'> About us</h1>

      <div className='About-Content'>
             <div className='About-content-top'>
             <h2>PROGG: Simplifying Business, Empowering Success</h2>
             <p>At Progg, we understand that navigating the complexities of business can be challenging. That's why we’re dedicated to
                making your business operations as straightforward and efficient as possible. Our innovative online software solutions are
                designed to streamline your processes, enhance productivity, and drive growth.</p>
           </div>
           
           <div className='About-content-bottom'>
             <h2>Transform your business with Progg—where simplicity meets efficiency.</h2>
             <p>At Progg – Redefine your business.</p>
           </div>
      </div>      
    </div>
  )
}

export default AboutUs