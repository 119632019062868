import React, { useEffect } from 'react'
import CoreOffering from './CoreOffering'
import StrategicBM from './StrategicBM'
import './Service.css'
import BusinessMan from '../../Assets/pexels-divinetechygirl-1181405.jpg'

const Services = () => {




  return (
    <div  id='ServicesWrapper' className='Services-Wrapper'>
       <div>
         <h1 className='Services'> Service's</h1>
          <div className='ServicesTop'>
            <div className='BM-Image-Container'>
              <img src={BusinessMan} alt="BusinessMan"/>
            </div>
                
            <div className='Services-Header-Content'>
                <h1> <span className='highlight'>PROGG</span> : Tailored Web Development and Customized Software Solutions</h1>
                <p>Welcome to Progg, where innovation meets efficiency. We specialize in delivering cutting-edge web development 
                    and customized software solutions designed to meet the unique needs of your business. Our mission is to empower your 
                    organization with technology that enhances functionality and drives growth.
                </p>
            </div>
          </div>
        <CoreOffering/>
        <StrategicBM/>
    </div>
    </div>
  )
}

export default Services