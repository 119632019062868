import React, { useRef, useState } from 'react'
import './Contactus.css'
import emailjs from '@emailjs/browser';
import square from '../../Assets/sqaure.png'
import { useContextApi } from '../Context/contextAPI'


const ContactForm = () => {
   let [userData , setUserData] = useState({
      Name:'',
      Email:'',
      ContactNumber:'',
      Message:''
   })

   let [response , SetResponse] = useState(false)
   let [Boolean , SetBoolean] = useState(false)

   
   
   const form = useRef();

   

   let HandleUserData = (e)=>{
      let {name , value} = e.target
      setUserData({...userData ,[name]:value })
   }




    let HandleMail = async(e)=>{
       e.preventDefault();
       SetBoolean(true)
     
       
       try {
          await emailjs.sendForm('service_3onqwwp', 'template_bwhna07', form.current, '96awX0IfnNRB0dqsu')
            SetResponse(true)
           setUserData({
               Name:'',
               Email:'',
               ContactNumber:'',
               Message:''
            })
          setTimeout(()=>{
            SetBoolean(false)
          SetResponse(false)
        },6000)
         
      } catch (error) {
         SetBoolean(false)
      }
    }

  return (

    <div id='Contact-Container' className='Contact-Container'>
      <h1>Contact us</h1>
      <div className='form-top-para'>
     
         <p>
            <span> <img className='Square-Img' src={square} alt="img"/></span>
               We value your feedback and inquiries. Whether you have a question, a suggestion, or need assistance, we're here to help.
               Please fill out the form below, and one of our team members will get back to you as soon as possible. Thank you for reaching
               out to us!
          </p>

      </div>
       <form  ref={form} onSubmit={HandleMail} className='Contact-us-form'>
            <h2>Get in Touch with us</h2>
          <div>
             <label> Name:</label>
             <input onChange={HandleUserData} value={userData.Name} name='Name' type='text' required/>
             {/* <div className='error'>{error.Name } </div> */}
          </div>
          <div>
             <label> E-mail:</label>
             <input onChange={HandleUserData} value={userData.Email}  name='Email' type='email' required/>
             {/* <div className='error'>{error.Email} </div> */}
          </div>
          <div>
             <label>Contact Number:</label>
             <input onChange={HandleUserData}value={userData.ContactNumber} name='ContactNumber' type='Number' required/>
              {/* <div className='error'>{error.ContactNumber}</div> */}
          </div>
          <div>
             <label>Description:</label>
              <textarea onChange={HandleUserData} value={userData.Message} name="Message" required></textarea>
               {/* <div className='error'>{error.Message} </div> */}
          </div>
          <button disabled={Boolean} className='Form-btn'> Submit</button>
          {response&&<div className='Response'>Thanks for Reaching us , our sales team will contact you shortly.</div>}
          
       </form>
    </div>
  )
}

export default ContactForm